<template>
    <div>
        <v-title title="Overpaid Orders"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Overpaid orders | Order slips</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid mt-3 mb-5">
            <template v-if="hasPermission('MerchantCentral/listOrderSlipsByApplication')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter @change="applyFilter" :filterFields="filterFields" :dropdownFilters="dropdownFilters" :has-date-range="true"></table-search-filter>
                </mercur-card>

                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                <merchant-data-table class="shadow" :options="options" :url="url" ref="table" @paginationSet="updatePagination"></merchant-data-table>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen" width="700px">
            <h3 class="font-weight-normal">{{ dialog.content }}</h3>
            <div>
                <mercur-spinner v-if="isGettingPayments"></mercur-spinner>
                <table v-if="payments.length" class="table border mb-0">
                    <thead>
                        <tr>
                            <th>##</th>
                            <th>Provider reference</th>
                            <th>Payment method</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="payment in payments" :key="payment.paymentId">
                            <td><mercur-radio class="m-0" v-model="selectedPayment" :value="payment"></mercur-radio></td>
                            <td>{{ payment.providerReference }}</td>
                            <td>{{ payment.paymentMethod }}</td>
                            <td><currency :value="payment.amount.value / 100" :type="payment.amount.currency"></currency></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template #footer>
                <mercur-button class="btn" @click.native="closeDialog" :disabled="isLoading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" :disabled="isGettingPayments || isLoading" @click.native="approveRefund">Confirm</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import Currency from '@/components/elements/Currency'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'OverpaidOrdersView',
    components: {
        MerchantDataTable,
        Pagination,
        TableSearchFilter,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.ORDERS.SLIPS.OVERVIEW.replace('{type}', 'CREDIT').replace('{agentId}', this.$store.state.auth.user.accountId),
            pagination: {},
            amountOfDisplayedItems: 50,
            dialogData: {},
            isLoading: false,
            isDownloading: false,
            isGettingPayments: false,
            payments: [],
            selectedPayment: null,
            dialog: {
                isOpen: false,
            },
            filterFields: {
                'accountNumber': { 'label': 'Account Number' },
                'slipNumber': { 'label': 'Slip Number' },
                'orderNumber': { 'label': 'OrderId' },
            },
            dropdownFilters: [
                {
                    column: 'status',
                    label: 'Slip Status',
                    operator: 'equals',
                    values: {
                        'New': 'NEW',
                        'Sent': 'SENT',
                        'Paid': 'PAID',
                    },
                },
            ],
            options: {
                columns: {
                    'Slip Number': {
                        field: 'slipNumber',
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Account Number': {
                        field: 'accountNumber',
                        valueFormatter: params => params.data.accountNumber || '-',
                    },
                    'Order Id': {
                        field: 'orderNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderWrapper',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                },
                            }
                        },
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Customer': {
                        field: ['billingInfo.address.firstName', 'billingInfo.address.lastName'],
                        link: (value, data) => {
                            return {
                                name: 'CustomerView',
                                params: {
                                    applicationId: data.applicationId,
                                    customerId: data.accountId,
                                },
                            }
                        },
                        filter: false,
                        sortable: false,
                    },
                    'Shop': { field: 'applicationName', filter: false, sortable: false },
                    'Date Created': { field: 'dateCreated', filter: false, sortable: true },
                    'Status': { field: 'status', statusChip: true, filter: false, sortable: false },
                    'Total': {
                        field: 'totals.total',
                        cellClass: 'u-bold',
                        filter: false,
                        sortable: false,
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.currency,
                            }
                        },
                    },
                    'Date From': {
                        field: 'dateFrom',
                        hide: true,
                    },
                    'Date To': {
                        field: 'dateTo',
                        hide: true,
                    },
                },
                actions: {
                    items: [
                        {
                            icon: 'fas fa-dollar-sign',
                            'tooltip': 'refund slip',
                            onClick: ({ data }) => {
                                this.openDialog(data)
                            },
                            isHidden: ({ data }) => {
                                return data.status !== 'NEW' || !this.hasPermission('requestRefund')
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                        {
                            icon: 'fas fa-cloud-download-alt',
                            tooltip: 'download slip',
                            onClick: ({ data }) => {
                                this.downloadSlip(data)
                            },
                            isDisabled: () => {
                                return this.isDownloading
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    computed: {
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapActions('order', ['fetchOrder']),
        ...mapActions('shop', ['checkShop']),
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        downloadSlip (data) {
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD.replace('{objectName}', data.slipUri)
            this.isDownloading = true
            this.addJob(url)
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                this.$emit('error', err.response)
            }).finally(() => {
                this.finishJob(url)
                this.isDownloading = false
            })
        },
        openDialog (data) {
            this.dialog.content = 'Are you sure you want to do this refund?'
            this.dialog.isOpen = true
            this.dialogData = data
            this.getOrderPayments(data.orderId)
        },
        closeDialog () {
            this.dialog.isOpen = false
            this.dialog.content = null
            this.dialogData = null
            this.payments = []
            this.selectedPayment = null
        },
        approveRefund () {
            const url = CONFIG.API.ROUTES.PAYMENT.REFUND_PAYMENT.replace('{customerId}', this.dialogData.accountId)
            const payload = {
                orderId: this.dialogData.orderId,
                paymentId: this.selectedPayment.paymentId,
                originalProviderReference: this.selectedPayment.providerReference,
                originalTransactionId: this.selectedPayment.transactionId,
                financeDocumentId: this.dialogData.slipId,
                originalMerchantReference: this.selectedPayment.sessionReference,
                amount: {
                    value: Math.abs(this.dialogData.totals.total),
                    currency: this.dialogData.currency,
                },
            }

            this.isLoading = true
            this.post(url, payload, 'Refund was successful').then(() => {
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
                this.dialogData = {}
                this.payments = []
                this.selectedPayment = null
            }).finally(() => {
                this.isLoading = false
            })
        },
        async getOrderPayments (orderId) {
            this.isGettingPayments = true
            const url = CONFIG.API.ROUTES.ORDERS.GET_ORDER_PAYMENTS.replace('{orderId}', orderId)
            await this.get(url).then((data) => {
                this.payments = data.data
                this.selectedPayment = this.payments[0]
            }).finally(() => {
                this.isGettingPayments = false
            })
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
    },
}
</script>
